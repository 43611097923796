<template>
    <div>
        <div style="padding:40px 40px;margin-bottom:10px;border: 2px solid #dcdfe6;border-radius: 8px">
            <el-form label-width="120px" v-model="search">
                <el-form-item label="赛制">
                    <el-radio-group v-model="search.rule">
                        <el-radio label="标准赛"></el-radio>
                        <el-radio label="V标准赛"></el-radio>
                        <el-radio label="高阶标准赛"></el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="国家" v-if="search.rule == '标准赛'">
                    <el-select v-model="search.nation" clearable >
                        <el-option
                                v-for="item in optionsList.nationOptions"
                                :key="'nation' + item.val"
                                :label="item.name"
                                :value="item.val"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="集团" v-if="search.rule != '标准赛'">
                    <el-select v-model="search.clan">
                        <el-option
                                v-for="item in optionsList.clanOptions"
                                :key="'clan' + item.val"
                                :label="item.name"
                                :value="item.val"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="search.searchKey" style="width: 202px"/>
                    <div v-show="search.moreShow">
                        <el-checkbox-group v-model="search.searchTextType">
                            <el-checkbox
                                    v-for="item in optionsList.searchTextTypeOptions"
                                    :key="'searchType'+item.val"
                                    :label="item.name"
                                    :name="item.val"
                                    @change="handleCheckBox('searchTextType',item.name)"
                            ></el-checkbox>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item v-show="search.moreKeyShow">
                    <el-button
                            @click="search.moreShow=true;search.moreKeyShow=false"
                    >新增搜索条件 ▽(搜索条件更详细)</el-button>
                </el-form-item>
                <div v-show="search.moreShow">
                    <el-form-item label="卡片种类" >
                        <el-checkbox-group v-model="search.unit_type">
                            <el-checkbox
                                    v-for="item in optionsList.unitTypeOptions"
                                    :key="'unit'+item.val"
                                    :label="item.name"
                                    :name="item.val"
                                    @change="handleCheckBox('unit_type',item.name)"
                            ></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="等级">
                        <el-checkbox-group v-model="search.grade">
                            <el-checkbox
                                    v-for="item in optionsList.gradeOptions"
                                    :key="'grade'+item.val"
                                    :label="item.name"
                                    :name="item.val"
                                    @change="handleCheckBox('grade',item.name)"
                            ></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>

                    <el-form-item label="力量">
                        <el-row>
                            <el-col :span="5">
                                <el-select v-model="search.power_min">
                                    <el-option
                                        v-for="item in optionsList.powerOptions"
                                        :key="'power_min'+item.val"
                                        :label="item.name"
                                        :value="item.val"
                                    ></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="2" style="text-align: center">~</el-col>
                            <el-col :span="5">
                                <el-select v-model="search.power_max">
                                    <el-option
                                            v-for="item in optionsList.powerOptions"
                                            :key="'power_max'+item.val"
                                            :label="item.name"
                                            :value="item.val"
                                    ></el-option>
                                </el-select>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="稀有度">
                        <el-select v-model="search.rarity">
                            <el-option
                                v-for="item in optionsList.rarityOptions"
                                :key="'rarity' + item.val"
                                :label="item.name"
                                :value="item.val"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="触发判定" >
                        <el-checkbox-group v-model="search.trigger">
                            <el-checkbox
                                    v-for="item in optionsList.triggerOptions"
                                    :key="'trigger'+item.val"
                                    :label="item.name"
                                    :name="item.val"
                                    @change="handleCheckBox('trigger',item.name)"
                            ></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="能力" >
                        <el-checkbox-group v-model="search.ability">
                            <el-checkbox
                                    v-for="item in optionsList.abilityOptions"
                                    :key="'ability'+item.val"
                                    :label="item.val"
                            >{{item.name}}</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </div>
                <el-form-item >
                    <el-button type="success" style="width: 200px" @click="handleSearch">搜索</el-button>
                    <el-button  style="width: 200px" @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    const InitSearch = {
        moreKeyShow:true,
        moreShow:false,
        // 搜索条件
        searchTextType:[],
        rule:"标准赛",
        nation:"",
        clan:"",
        searchKey:"",
        unit_type:["全部"],
        grade:["全部"],
        power_min:"",
        power_max:"",
        rarity:"",
        trigger:["全部"],
        ability:[],
    };
    export default {
        name: "search-form",
        data() {
            return {
                search:{
                    moreKeyShow:true,
                    moreShow:false,
                    // 搜索条件
                    searchTextType:[],
                    rule:"标准赛",
                    nation:"",
                    clan:"",
                    searchKey:"",
                    unit_type:["全部"],
                    grade:["全部"],
                    power_min:"",
                    power_max:"",
                    rarity:"",
                    trigger:["全部"],
                    ability:[],
                },
                optionsList:{
                    clanOptions:[
                        {name:"光辉骑士团",val:"光辉骑士团"},
                        {name:"占卜魔法团",val:"占卜魔法团"},
                        {name:"天使之羽",val:"天使之羽"},
                        {name:"暗影骑士团",val:"暗影骑士团"},
                        {name:"黄金骑士团",val:"黄金骑士团"},
                        {name:"创世",val:"创世"},
                        {name:"阳炎",val:"阳炎"},
                        {name:"射干玉",val:"射干玉"},
                        {name:"太刀风",val:"太刀风"},
                        {name:"丛云",val:"丛云"},
                        {name:"鸣神",val:"鸣神"},
                        {name:"搏击新星",val:"搏击新星"},
                        {name:"次元警察",val:"次元警察"},
                        {name:"异界来客",val:"异界来客"},
                        {name:"链环傀儡",val:"链环傀儡"},
                        {name:"钢钉兄弟会",val:"钢钉兄弟会"},
                        {name:"黑暗不法者",val:"黑暗不法者"},
                        {name:"黯月",val:"黯月"},
                        {name:"齿轮编年史",val:"齿轮编年史"},
                        {name:"雄伟深蓝",val:"雄伟深蓝"},
                        {name:"百慕大三角",val:"百慕大三角"},
                        {name:"沧海军势",val:"沧海军势"},
                        {name:"百群",val:"百群"},
                        {name:"大自然",val:"大自然"},
                        {name:"永生密酒",val:"永生密酒"},
                        {name:"BanG Dream!",val:"BanG Dream!"},
                    ],
                    searchTextTypeOptions:[
                        {name:"全部",val:""},
                        {name:"卡片名称",val:"name_cn"},
                        {name:"文本",val:"ability"},
                        {name:"编号",val:"number"},
                        {name:"种族",val:"race"},
                        {name:"绘师",val:"illustrator"},
                    ],
                    nationOptions:[
                        {name:"王冠圣域",val:"王冠圣域"},
                        {name:"龙族帝国",val:"龙族帝国"},
                        {name:"布兰特之门",val:"布兰特之门"},
                        {name:"暗邦",val:"暗邦"},
                        {name:"基元",val:"基元"},
                        {name:"圣律诗院",val:"圣律诗院"},
                    ],
                    unitTypeOptions:[
                        {name:"全部",val:"all"},
                        {name:"普通单位",val:"普通单位"},
                        {name:"触发单位",val:"触发单位"},
                        {name:"G单位",val:"G单位"},
                        {name:"普通指令",val:"普通指令"},
                        {name:"闪现指令",val:"闪现指令"},
                        {name:"设置指令",val:"设置指令"},
                        {name:"其他",val:"其他"},
                    ],
                    gradeOptions:[
                        {name:"全部",val:"all"},
                        {name:"0",val:"0"},
                        {name:"1",val:"1"},
                        {name:"2",val:"2"},
                        {name:"3",val:"3"},
                        {name:"4",val:"4"},
                        {name:"5",val:"5"},
                    ],
                    powerOptions:[
                        {name:"未指定",val:"未指定"},
                        {name:"0",val:"0"},
                        {name:"333",val:"333"},
                        {name:"3000",val:"3000"},
                        {name:"4000",val:"4000"},
                        {name:"5000",val:"5000"},
                        {name:"6000",val:"6000"},
                        {name:"7000",val:"7000"},
                        {name:"8000",val:"8000"},
                        {name:"9000",val:"9000"},
                        {name:"10000",val:"10000"},
                        {name:"11000",val:"11000"},
                        {name:"12000",val:"12000"},
                        {name:"13000",val:"13000"},
                        {name:"14000",val:"14000"},
                        {name:"15000",val:"15000"},
                        {name:"16000",val:"16000"},
                        {name:"17000",val:"17000"},
                        {name:"18000",val:"18000"},
                        {name:"23000",val:"23000"},
                        {name:"24000",val:"24000"},
                        {name:"25000",val:"25000"},
                        {name:"27000",val:"27000"},
                        {name:"30000",val:"30000"},
                        {name:"70000",val:"70000"},
                    ],
                    rarityOptions:[
                        {name:"C",val:"C"},
                        {name:"R",val:"R"},
                        {name:"RR",val:"RR"},
                        {name:"RRR",val:"RRR"},
                        {name:"ORR",val:"ORR"},
                        {name:"SP",val:"SP"},
                        {name:"CNR",val:"CNR"},
                        {name:"DSR",val:"DSR"},
                        {name:"WO",val:"WO"},
                        {name:"PR",val:"PR"},
                    ],
                    triggerOptions:[
                        {name:"全部",val:"all"},
                        {name:"超",val:"超"},
                        {name:"治",val:"治"},
                        {name:"☆",val:"☆"},
                        {name:"引",val:"引"},
                        {name:"前",val:"前"},
                        {name:"醒",val:"醒"},
                    ],
                    abilityOptions:[
                        {name:"【永】永续能力",val:"【永】"},
                        {name:"【自】自动能力",val:"【自】"},
                        {name:"【起】起动能力",val:"【起】"},
                        {name:"其他",val:"其他"},
                    ],
                }
            }
        },
        methods: {
            handleSearch() {
                this.$emit("searchSuccess",this.search);
            },
            handleReset(){
                this.$set(this.search,"searchTextType",InitSearch.searchTextType);
                this.$set(this.search,"rule",InitSearch.rule);
                this.$set(this.search,"nation",InitSearch.nation);
                this.$set(this.search,"clan",InitSearch.clan);
                this.$set(this.search,"searchKey",InitSearch.searchKey);
                this.$set(this.search,"unit_type",InitSearch.unit_type);
                this.$set(this.search,"grade",InitSearch.grade);
                this.$set(this.search,"power_min",InitSearch.power_min);
                this.$set(this.search,"power_max",InitSearch.power_max);
                this.$set(this.search,"rarity",InitSearch.rarity);
                this.$set(this.search,"trigger",InitSearch.trigger);
                this.$set(this.search,"ability",InitSearch.ability);
            },
            handleCheckBox(type,val){
                var hasExists = false;
                switch (type) {
                    case "unit_type":
                        this.search.unit_type.forEach((v)=>{
                            if (v === val){
                                hasExists = true;
                            }
                        });
                        if (hasExists === true){
                            if (val === "全部"){
                                this.search.unit_type = ["全部"];
                            }else{
                                let newArr = [];
                                this.search.unit_type.forEach((v)=>{
                                    if (v !== "全部"){
                                        newArr.push(v);
                                    }
                                });
                                this.search.unit_type = newArr;
                            }
                        }
                        break;
                    case "grade":
                        this.search.grade.forEach((v)=>{
                            if (v === val){
                                hasExists = true;
                            }
                        });
                        if (hasExists === true){
                            if (val === "全部"){
                                this.search.grade = ["全部"];
                            }else{
                                let newArr = [];
                                this.search.grade.forEach((v)=>{
                                    if (v !== "全部"){
                                        newArr.push(v);
                                    }
                                });
                                this.search.grade = newArr;
                            }
                        }
                        break;
                    case "trigger":
                        this.search.trigger.forEach((v)=>{
                            if (v === val){
                                hasExists = true;
                            }
                        });
                        if (hasExists === true){
                            if (val === "全部"){
                                this.search.trigger = ["全部"];
                            }else{
                                let newArr = [];
                                this.search.trigger.forEach((v)=>{
                                    if (v !== "全部"){
                                        newArr.push(v);
                                    }
                                });
                                this.search.trigger = newArr;
                            }
                        }
                        break;
                    case "ability":
                        break;
                    case "searchTextType":
                        this.search.searchTextType.forEach((v)=>{
                            if (v === val){
                                hasExists = true;
                            }
                        });
                        if (hasExists === true){
                            if (val === "全部"){
                                this.search.searchTextType = ["全部"];
                            }else{
                                let newArr = [];
                                this.search.searchTextType.forEach((v)=>{
                                    if (v !== "全部"){
                                        newArr.push(v);
                                    }
                                });
                                this.search.searchTextType = newArr;
                            }
                        }
                        break;
                    default:
                        return;
                }
            }
        },
        created() {
            /**
             * 返回搜索结果
             */
            if(this.$route.query.searchBack === true || this.$route.query.searchBack=== "true"){
                this.$set(this.search,"searchTextType",this.$store.state.cardSearchData.searchTextType);
                this.$set(this.search,"rule",this.$store.state.cardSearchData.rule);
                this.$set(this.search,"nation",this.$store.state.cardSearchData.nation);
                this.$set(this.search,"clan",this.$store.state.cardSearchData.clan);
                this.$set(this.search,"searchKey",this.$store.state.cardSearchData.searchKey);
                this.$set(this.search,"unit_type",this.$store.state.cardSearchData.unit_type);
                this.$set(this.search,"grade",this.$store.state.cardSearchData.grade);
                this.$set(this.search,"power_min",this.$store.state.cardSearchData.power_min);
                this.$set(this.search,"power_max",this.$store.state.cardSearchData.power_max);
                this.$set(this.search,"rarity",this.$store.state.cardSearchData.rarity);
                this.$set(this.search,"trigger",this.$store.state.cardSearchData.trigger);
                this.$set(this.search,"ability",this.$store.state.cardSearchData.ability);
                this.$set(this.search,"moreKeyShow",this.$store.state.cardSearchData.moreKeyShow);
                this.$set(this.search,"moreShow",this.$store.state.cardSearchData.moreShow);
            }
        }
    }
</script>

<style scoped>

</style>
