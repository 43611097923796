<template>
    <div class="card-list-main">
        <div class="article-header">
            <h2>卡片列表</h2>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="article-nnav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>卡片检索</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--搜索框-->
        <div class="search-form">
            <SearchForm @searchSuccess="handleSearch"></SearchForm>
            <!--集团国家列表-->
            <div v-if="!productShow">
                <el-tag type="warning" style="margin-right: 20px" size="medium"
                    class="search-tag">搜索结果总数:{{ total }}</el-tag>
                <el-tag type="danger" style="margin-right: 50px" size="medium" :closable="true"
                    v-if="search.pack_short_name" @close="handleClosePackShotName"
                    class="search-tag">按产品编号:{{ search.pack_short_name }}</el-tag>
            </div>
            <div v-if="!productShow" class="search-nation-clan">
                <el-radio-group v-model="nation2clanVal" size="medium">
                    <el-radio v-for="item in nation2clan" :key="item" :label="item" border
                        @change="handleNation2Clan"></el-radio>
                </el-radio-group>
            </div>
            <!--排序/展示方式-->
            <div v-if="!productShow">
                <div class="m-fl">
                    <el-select v-model="sort" @change="handleSort">
                        <el-option label="产品最新发售" value="1"></el-option>
                        <el-option label="卡片编号顺序" value="2"></el-option>
                        <el-option label="类别" value="3"></el-option>
                        <el-option label="等级降序" value="4"></el-option>
                        <el-option label="等级升序" value="5"></el-option>
                        <el-option label="力量降序" value="6"></el-option>
                        <el-option label="力量升序" value="7"></el-option>
                    </el-select>
                </div>
                <div class="m-fr">
                    <el-radio-group v-model="showType">
                        <el-radio-button label="缩略图列表展示"></el-radio-button>
                        <el-radio-button label="文字列表展示"></el-radio-button>
                    </el-radio-group>
                </div>
                <div class="m-clear-both"></div>
                <!--展示列表-->
                <div class="card-list-area">
                    <div v-if="showType == '缩略图列表展示'">
                        <ImgList :listData="listData"></ImgList>
                    </div>
                    <div v-if="showType == '文字列表展示'">
                        <TextList :listData="listData"></TextList>
                    </div>
                </div>
            </div>
        </div>
        <!--产品列表-->
        <ProductList v-show="productShow" @clickProductItemCallback="handleClickProductItem"></ProductList>
    </div>
</template>

<script>
import ImgList from './components/img-list'
import TextList from './components/text-list'
import SearchForm from "./components/search-form";
import ProductList from "./components/product-list";

export default {
    name: "index",
    components: { SearchForm, ImgList, TextList, ProductList },
    data() {
        return {
            showType: "缩略图列表展示",
            sort: "1",
            listData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            search: {},
            productShow: true,
            nation2clan: [],
            nation2clanVal: "",
            rqOnce: false
        }
    },
    methods: {
        handleClosePackShotName() {
            this.search.pack_short_name = "";
            this.currentPage = 1;
            this.listData = [];

            // 保留搜索结果
            this.setSearchCookie();

            this.getList();
        },
        handleClickProductItem(obj) {
            this.handleScrollToTop();
            this.search = {};
            this.search.pack_short_name = obj.no;
            this.currentPage = 1;
            this.listData = [];
            this.getList();
        },
        getList() {
            /**
             * 防止多次请求
             */
            if (this.rqOnce === true) {
                return;
            }
            this.rqOnce = true;

            this.productShow = false;
            let page = this.currentPage;
            let limit = this.pageSize;
            let search = this.search;
            let sort = this.sort;
            this.$axios.post("site/siteCard/getList", { page, limit, search, sort })
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.listData = this.listData.concat(res.data.data.list);
                        this.total = res.data.data.total;
                        if (this.nation2clan.length == 0) {
                            this.nation2clan = res.data.data.nation2clan;
                        }
                    } else {
                        this.$message.error(res.data.msg)
                    }
                    this.rqOnce = false;
                })

        },
        handleSort() {
            this.currentPage = 1;
            this.listData = [];
            this.getList();
        },
        handleSearch(obj) {
            this.currentPage = 1;
            // 保留产品包搜索
            obj.pack_short_name = this.search.pack_short_name;
            this.search = obj;
            this.listData = [];

            // 搜索后国家集团选项置空
            this.search.nation2clan = "";
            this.nation2clanVal = "";
            this.nation2clan = [];

            // 保留搜索结果
            this.setSearchCookie();

            this.getList();
        },
        handleNation2Clan() {
            this.currentPage = 1;
            this.search.nation2clan = this.nation2clanVal;
            this.listData = [];
            this.getList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        handleScrollToTop() {
            window.scrollTo(0, 0);
        },
        handleScroll() {
            let clientHeight = document.documentElement.clientHeight;//可视高度
            let scrollHeight = document.documentElement.scrollHeight;//内容高度
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            //console.log(clientHeight+"|"+scrollHeight+"|"+scrollTop+"|"+this.rqOnce);

            // 内容高度=滚动高度+加可视高度
            if (scrollHeight <= (clientHeight + scrollTop)) {

                if (this.listData.length >= this.total || this.rqOnce === true) {
                    return;
                }
                this.currentPage++;
                console.log("run request", this.currentPage);
                this.getList();

            }
        },
        setSearchCookie() {
            this.$store.state.cardSearchData = this.search;
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
    },
    created() {
        if (this.$route.query.searchBack === true || this.$route.query.searchBack === "true") {
            this.search = this.$store.state.cardSearchData;
            this.getList();
        }
    }
}
</script>

<style scoped>
.article-pagination {
    text-align: center;
    padding: 20px 0;

}

.card-list-main {
    width: 100%;
    height: auto;
    background-color: white;
}

.article-header {
    text-align: center;
    padding-top: 20px;
}

.article-nnav {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.search-form {
    width: 60%;
    margin: 0 auto;
}

.card-list-area {
    margin-top: 40px;
    width: 100%;
    height: auto;
}

.search-nation-clan {
    padding: 40px 0;
}

.search-tag {
    font-size: 16px;
    font-weight: bold;
    color: #111111;
}
</style>
